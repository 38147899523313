import { combineReducers } from "redux"

import user from './user'
import matches from './matches'
import players from './players'
import notifications from './notifications'

export default combineReducers({
  user,
  matches,
  players,
  notifications,
})
