import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useQuery } from 'util/browser'
import { isAuthenticated, setAuthHeader } from "util/auth"
import { selectUserId, selectUserIsAdmin, selectUserIsLoading } from 'selectors/user'
import { getUser } from 'actions/user'

import AppLayout from './components/layout/AppLayout'
import Dashboard from './components/Dashboard'
import Login from './components/Login'
import NotFound from './components/NotFound'
import SetPassword from './components/SetPassword'
import Elo from 'components/Elo'
import Glicko from 'components/Glicko'
import Compare from 'components/Compare'
import History from 'components/History'
import AddMatch from 'components/AddMatch'

// TODO: Find a better pattern for AppLayout
export default function App() {
  return (
    <Switch>
      <Route exact path={['/', '/dashboard']}>
        <AppLayout>
          <Dashboard />
        </AppLayout>
      </Route>
      <Route path="/elo">
        <AppLayout>
          <Elo />
        </AppLayout>
      </Route>
      <Route path="/glicko">
        <AppLayout>
          <Glicko />
        </AppLayout>
      </Route>
      <Route path="/history">
        <AppLayout>
          <History />
        </AppLayout>
      </Route>
      <Route path="/compare">
        <AppLayout>
          <Compare />
        </AppLayout>
      </Route>
      <AuthenticatedLinkRoute path='/set-password'>
        <PrivateRoute>
          <AppLayout>
            <SetPassword />
          </AppLayout>
        </PrivateRoute>
      </AuthenticatedLinkRoute>
      <PrivateRoute path='/add'>
        <AdminRoute>
          <AppLayout>
            <AddMatch />
          </AppLayout>
        </AdminRoute>
      </PrivateRoute>
      <Route path='/login'>
        <AppLayout>
          <Login />
        </AppLayout>
      </Route>
      <Route>
        <AppLayout>
          <NotFound />
        </AppLayout>
      </Route>
    </Switch>
  )
}

function AuthenticatedLinkRoute({ children, path, ...rest }) {
  const query = useQuery()
  const token = query.get("token")
  // const parsed = parseJwt(token)
  // let username, userId

  if (token) {
    setAuthHeader(token)
    // username = parsed.data.username
    // userId = parsed.data.user_id
    query.delete("token")
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return (
          token ? (
            <Redirect to={{
              pathname: location.pathname,
              search: query.toString(),
              // state: { userId: userId, username }
            }}
          />
          ) : (
            children
          )
        )
      }
      }
    />
  )
}

function PrivateRoute({ children, ...rest }) {
  const dispatch = useDispatch()
  const isUserAuthenticated = isAuthenticated()
  const location = useLocation()

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(getUser())
    }
  }, [dispatch, isUserAuthenticated])

  const Redirection = 
    <Redirect to={{
        pathname: '/login',
        state: { from: location }
      }}
    />

  return (
    <Route
      {...rest}
      render={() =>
        isUserAuthenticated ? (
          children
        ) : Redirection
      }
    />
  )
}

function AdminRoute({ children, ...rest }) {
  const loading = useSelector(selectUserIsLoading)
  const isUserAdmin = useSelector(selectUserIsAdmin)
  const userId = useSelector(selectUserId)

  if (loading || !userId) { return null }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isUserAdmin ? (
          children
        ) : (
          <Redirect to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
