import React, { useState } from 'react'
import { TextField, DatePicker, DayOfWeek, Dropdown, PrimaryButton } from '@fluentui/react'
import { useDispatch } from 'react-redux'
import { addMatch } from 'actions/matches'

const dayPickerStrings = {
  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  goToToday: 'Go to today',
  prevMonthAriaLabel: 'Go to previous month',
  nextMonthAriaLabel: 'Go to next month',
  prevYearAriaLabel: 'Go to previous year',
  nextYearAriaLabel: 'Go to next year'
}

// Generate time options for the Dropdown
const timeOptions = []
for (let hour = 0; hour < 24; hour++) {
  for (let minute = 0; minute < 60; minute += 30) { // 30-minute intervals
    const hourFormatted = hour < 10 ? `0${hour}` : hour
    const minuteFormatted = minute < 10 ? `0${minute}` : minute
    timeOptions.push({ key: `${hourFormatted}:${minuteFormatted}`, text: `${hourFormatted}:${minuteFormatted}` })
  }
}

const AddMatch = () => {
  const dispatch = useDispatch()
  const [matchDate, setMatchDate] = useState(null)
  const [matchTime, setMatchTime] = useState(null)
  const [event, setEvent] = useState('')
  const [roundGame, setRoundGame] = useState('')
  const [restreamerLocation, setRestreamerLocation] = useState('')
  const [player1Name, setPlayer1Name] = useState('')
  const [player2Name, setPlayer2Name] = useState('')
  const [player1Score, setPlayer1Score] = useState('')
  const [player2Score, setPlayer2Score] = useState('')

  const handleSubmit = () => {
    const fullDateTime = new Date(matchDate) // This creates a date at midnight in the user's local timezone
    if (matchTime) {
      const [hours, minutes] = matchTime.split(':')
      fullDateTime.setHours(hours, minutes) // Adjusts the date object to the specified time, still in local timezone
    }
  
    // Convert the date-time to UTC
    const utcDateTime = new Date(fullDateTime.getTime()).toISOString()
  
    dispatch(addMatch({
      date_time: utcDateTime, // Sending in ISO 8601 format in UTC
      event,
      round_game: roundGame,
      restreamer_location: restreamerLocation,
      player_1_name: player1Name,
      player_2_name: player2Name,
      player_1_score: parseInt(player1Score, 10),
      player_2_score: parseInt(player2Score, 10)
    })).then(() => {
      setMatchDate(null)
      setMatchTime(null)
      setEvent('')
      setRoundGame('')
      setRestreamerLocation('')
      setPlayer1Name('')
      setPlayer2Name('')
      setPlayer1Score('')
      setPlayer2Score('')
    })
  }

  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '20px' }}
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <DatePicker
        onSelectDate={setMatchDate}
        label="Match Date"
        firstDayOfWeek={DayOfWeek.Sunday}
        strings={dayPickerStrings}
        placeholder="Select a date..."
        ariaLabel="Select a date"
      />
      <Dropdown
        label="Match Time"
        placeholder="Select time"
        options={timeOptions}
        onChange={(e, option) => setMatchTime(option.key)}
        selectedKey={matchTime}
      />
      <TextField label="Event" value={event} onChange={(e, newValue) => setEvent(newValue)} />
      <TextField label="Round/Game" value={roundGame} onChange={(e, newValue) => setRoundGame(newValue)} />
      <TextField label="Restreamer/Location" value={restreamerLocation} onChange={(e, newValue) => setRestreamerLocation(newValue)} />
      <TextField label="Player 1 Name" value={player1Name} onChange={(e, newValue) => setPlayer1Name(newValue)} />
      <TextField label="Player 2 Name" value={player2Name} onChange={(e, newValue) => setPlayer2Name(newValue)} />
      <TextField label="Player 1 Score" type="number" value={player1Score} onChange={(e, newValue) => setPlayer1Score(newValue)} />
      <TextField label="Player 2 Score" type="number" value={player2Score} onChange={(e, newValue) => setPlayer2Score(newValue)} />
      <PrimaryButton text="Add Match" type="submit" style={{ marginTop: '20px' }} />
    </form>
  )
}

export default AddMatch
