import {
  FETCH_MATCHES_FAILURE,
  FETCH_MATCHES_SUCCESS,
  FETCH_MATCHES_REQUESTED,
  SEARCH_MATCHES_SUCCESS,
  SEARCH_MATCHES_REQUESTED,
  SEARCH_MATCHES_FAILURE,
} from 'constants/actions'

const initialState = {
  isLoading: false,
  isError: false,
  matches: [],
}

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case FETCH_MATCHES_REQUESTED:
    case SEARCH_MATCHES_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_MATCHES_SUCCESS:
    case SEARCH_MATCHES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        matches: data.matches,
      }
    case FETCH_MATCHES_FAILURE:
    case SEARCH_MATCHES_FAILURE:
      return {
        ...state,
        isError: true,
      }
    default:
      return state
  }
}

export default reducer
