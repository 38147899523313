import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { TextField, PrimaryButton, Spinner } from '@fluentui/react'

import { login } from 'actions/user'
import { isAuthenticated } from 'util/auth'
import { selectUserIsAdmin, selectUserIsError, selectUserIsLoading } from 'selectors/user'

import './Login.scss'

const Login = () => {
  const history = useHistory()
  const isUserAuthenticated = isAuthenticated()
  const isLoading = useSelector(selectUserIsLoading)
  const isError = useSelector(selectUserIsError)
  const isAdmin = useSelector(selectUserIsAdmin)

  const dispatch = useDispatch()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const handleSetUsername = (e) => setUsername(e.target.value)
  const handleSetPassword = (e) => setPassword(e.target.value)
  const handleLogin = () => dispatch(login(username, password))

  const disabled = isEmpty(username) || isEmpty(password)

  useEffect(() => {
    if (isUserAuthenticated && isAdmin && !isLoading) {
      history.push('/')
    }
  }, [isUserAuthenticated, isAdmin, history, isLoading])

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 && !disabled) {
      handleLogin()
    }
  }

  return (
    <div className="login">
      { isError ? <p>Invalid username or password, please try again.</p> : null }
      <h4>Login</h4>
      <TextField label="Username" value={username} onChange={handleSetUsername} onKeyDown={handleKeyPress} />
      <TextField
        type="password"
        canRevealPassword
        revealPasswordAriaLabel="Show password"
        label="Password"
        value={password}
        onChange={handleSetPassword}
        onKeyDown={handleKeyPress}
      />
      { isLoading ? <Spinner /> : <PrimaryButton className="submit" text="Sign In" onClick={handleLogin} disabled={disabled} /> }
    </div>
  )
}

export default Login
