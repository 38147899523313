import { getComparison, getPlayerOptions } from "actions/players"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectCompare, selectSearchOptions1, selectSearchOptions2 } from "selectors/players"

import { DetailsList, ComboBox } from "@fluentui/react"
import { debounce } from "lodash"
import StatCard from "./shared/StatCard"

import './Compare.scss'

const defaultColumns = [
  {
    key: 'matchNumber',
    name: '#',
    minWidth: 50,
    maxWidth: 50,
    fieldName: 'match_number',
    isResizable: false,
  },
  {
    key: 'event',
    name: 'Event',
    minWidth: 125,
    fieldName: 'event',
  },
  {
    key: 'edition',
    name: 'Edition',
    minWidth: 125,
    maxWidth: 125,
    fieldName: 'edition',
    isResizable: true,
  },
  {
    key: 'roundGame',
    name: 'Round/Game',
    minWidth: 100,
    maxWidth: 100,
    fieldName: 'round_game',
    isResizable: true,
  },
  {
    key: 'restreamerLocation',
    name: 'Restreamer/Location',
    minWidth: 200,
    maxWidth: 200,
    fieldName: 'restreamer_location',
    isResizable: true,
  },
  {
    key: 'date',
    name: 'Date',
    minWidth: 100,
    maxWidth: 100,
    fieldName: 'date_time',
    isResizable: false,
  },
  {
    key: 'player1',
    name: 'Player 1',
    minWidth: 150,
    maxWidth: 150,
    fieldName: "player1_name",
    isResizable: true,
  },
  {
    key: 'player1Score',
    name: 'Score',
    minWidth: 50,
    maxWidth: 50,
    fieldName: "player1_score",
    isResizable: true,
  },
  {
    key: 'player2',
    name: 'Player 2',
    minWidth: 150,
    maxWidth: 150,
    fieldName: "player2_name",
    isResizable: true,
  },
  {
    key: 'player2Score',
    name: 'Score',
    minWidth: 50,
    maxWidth: 50,
    fieldName: "player2_score",
    isResizable: true,
  },
]

const Compare = () => {
  const dispatch = useDispatch()
  const compare = useSelector(selectCompare)
  const searchOptions1 = useSelector(selectSearchOptions1) || []
  const searchOptions2 = useSelector(selectSearchOptions2) || []
  const [player1, setPlayer1] = useState(null)
  const [player2, setPlayer2] = useState(null)

  const fetchPlayers1 = useCallback(debounce((filter) => {
    dispatch(getPlayerOptions(filter, true))
  }, 300), [])

  const fetchPlayers2 = useCallback(debounce((filter) => {
    dispatch(getPlayerOptions(filter, false))
  }, 300), [])

  useEffect(() => {
    if (player1 && player2) {
      dispatch(getComparison(player1.key, player2.key))
    }
  }, [dispatch, player1, player2])

  const onChangePlayer1 = (_, option) => {
    setPlayer1(option)
  }

  const onChangePlayer2 = (_, option) => {
    setPlayer2(option);
  }

  const player1Percent = parseFloat(compare?.odds?.player1_odds * 100 || 0).toFixed(2)+"%"
  const player2Percent = parseFloat(compare?.odds?.player2_odds * 100 || 0).toFixed(2)+"%"

  return (
    <div className="compare">
      <div className="playerCompare">
        <div className="player">
          <ComboBox
            label="Player 1"
            placeholder="Select or search a player"
            allowFreeform
            autoComplete="on"
            options={searchOptions1}
            onChange={onChangePlayer1}
            onInputValueChange={fetchPlayers1}
          />
          <h3>{compare?.player1?.name}</h3>
          <h4>Last played: {compare?.player1?.last_match_date}</h4>
          <StatCard title="Win chance" value={player1Percent} />
        </div>
        <div className="player">
          <ComboBox
              label="Player 2"
              placeholder="Select or search a player"
              allowFreeform
              autoComplete="on"
              options={searchOptions2}
              onChange={onChangePlayer2}
              onInputValueChange={fetchPlayers2}
            />
          <h3>{compare?.player2?.name}</h3>
          <h4>Last played: {compare?.player2?.last_match_date}</h4>
          <StatCard title="Win chance" value={player2Percent} />
        </div>
      </div>
      <div className="matchTable" data-is-scrollable={true}>
        <h1>MATCH HISTORY</h1>
        <DetailsList
          items={compare?.match_history || []}
          columns={defaultColumns}
          getKey={(item) => item.id}
          setKey="none"
          selectionMode="none"
          checkboxVisibility={2}
        />
      </div>
    </div>
  )
}

export default Compare
