export const USER_SIGN_OUT = 'USER_SIGN_OUT'

export const AUTHENTICATE_USER_REQUESTED = 'AUTHENTICATE_USER_REQUESTED'
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS'
export const AUTHENTICATE_USER_FAILURE = 'AUTHENTICATE_USER_FAILURE'

export const SIGN_UP_REQUESTED = 'SIGN_UP_REQUESTED'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'

export const SET_PASSWORD_REQUESTED = 'SET_PASSWORD_REQUESTED'
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS'
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE'

export const FETCH_USER_REQUESTED = 'FETCH_USER_REQUESTED'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export const FETCH_MATCHES_REQUESTED = 'FETCH_MATCHES_REQUESTED'
export const FETCH_MATCHES_SUCCESS = 'FETCH_MATCHES_SUCCESS'
export const FETCH_MATCHES_FAILURE = 'FETCH_MATCHES_FAILURE'

export const SEARCH_MATCHES_REQUESTED = 'SEARCH_MATCHES_REQUESTED'
export const SEARCH_MATCHES_SUCCESS = 'SEARCH_MATCHES_SUCCESS'
export const SEARCH_MATCHES_FAILURE = 'SEARCH_MATCHES_FAILURE'

export const ADD_MATCH_REQUESTED = 'ADD_MATCH_REQUESTED'
export const ADD_MATCH_SUCCESS = 'ADD_MATCH_SUCCESS'
export const ADD_MATCH_FAILURE = 'ADD_MATCH_FAILURE'

export const FETCH_ELO_REQUESTED = 'FETCH_ELO_REQUESTED'
export const FETCH_ELO_SUCCESS = 'FETCH_ELO_SUCCESS'
export const FETCH_ELO_FAILURE = 'FETCH_ELO_FAILURE'

export const FETCH_GLICKO_REQUESTED = 'FETCH_GLICKO_REQUESTED'
export const FETCH_GLICKO_SUCCESS = 'FETCH_GLICKO_SUCCESS'
export const FETCH_GLICKO_FAILURE = 'FETCH_GLICKO_FAILURE'

export const FETCH_PLAYERS_HISTORY_REQUESTED = 'FETCH_PLAYERS_HISTORY_REQUESTED'
export const FETCH_PLAYERS_HISTORY_SUCCESS = 'FETCH_PLAYERS_HISTORY_SUCCESS'
export const FETCH_PLAYERS_HISTORY_FAILURE = 'FETCH_PLAYERS_HISTORY_FAILURE'

export const FETCH_COMPARE_REQUESTED = 'FETCH_COMPARE_REQUESTED'
export const FETCH_COMPARE_SUCCESS = 'FETCH_COMPARE_SUCCESS'
export const FETCH_COMPARE_FAILURE = 'FETCH_COMPARE_FAILURE'

export const FETCH_PLAYER_OPTIONS_REQUESTED = 'FETCH_PLAYER_OPTIONS_REQUESTED'
export const FETCH_PLAYER_OPTIONS_SUCCESS = 'FETCH_PLAYER_OPTIONS_SUCCESS'
export const FETCH_PLAYER_OPTIONS_FAILURE = 'FETCH_PLAYER_OPTIONS_FAILURE'

export const EXPORT_ELO_REQUESTED = 'EXPORT_ELO_REQUESTED'
export const EXPORT_ELO_SUCCESS = 'EXPORT_ELO_SUCCESS'
export const EXPORT_ELO_FAILURE = 'EXPORT_ELO_FAILURE'

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
