import {
  AUTHENTICATE_USER_FAILURE,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_REQUESTED,
  SET_PASSWORD_FAILURE,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_REQUESTED,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  FETCH_USER_REQUESTED,
  USER_SIGN_OUT,
  SIGN_UP_SUCCESS,
  SIGN_UP_REQUESTED,
  SIGN_UP_FAILURE,
} from 'constants/actions'

const initialState = {
  isLoading: false,
  isError: false,
  id: null,
  name: null,
  role: null,
}

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case FETCH_USER_REQUESTED:
    case AUTHENTICATE_USER_REQUESTED:
    case SET_PASSWORD_REQUESTED:
    case SIGN_UP_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        hasSetPassword: true,
      }
    case FETCH_USER_SUCCESS:
    case AUTHENTICATE_USER_SUCCESS:
    case SIGN_UP_SUCCESS:
      return {
        ...initialState,
        ...data,
      }
    case FETCH_USER_FAILURE:
    case AUTHENTICATE_USER_FAILURE:
    case SET_PASSWORD_FAILURE:
    case SIGN_UP_FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
      }
    case USER_SIGN_OUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default reducer
