import _ from 'lodash'

export const selectUserIsLoading = (state) => state.user.isLoading

export const selectUserIsError= (state) => state.user.isError

export const selectUserIsAdmin = (state) => state.user.role === 'admin'

export const selectUser = (state) => state.user

export const selectUserId = (state) => state.user.id

export const selectUserHasSetPassword = (state) => state.user.hasSetPassword
