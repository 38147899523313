import { getMatches, searchMatches } from "actions/matches"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectMatches } from "selectors/matches"

import { DetailsList, TextField } from "@fluentui/react"

import "./Dashboard.scss"
import { useCallback } from "react"
import { debounce } from "lodash"

const defaultColumns = [
  {
    key: 'matchNumber',
    name: '#',
    minWidth: 25,
    maxWidth: 75,
    fieldName: 'match_number',
    isResizable: false,
  },
  {
    key: 'event',
    name: 'Event',
    minWidth: 100,
    fieldName: 'event',
  },
  {
    key: 'edition',
    name: 'Edition',
    minWidth: 50,
    maxWidth: 125,
    fieldName: 'edition',
    isResizable: true,
  },
  {
    key: 'roundGame',
    name: 'Round/Game',
    minWidth: 100,
    maxWidth: 100,
    fieldName: 'round_game',
    isResizable: true,
  },
  {
    key: 'restreamerLocation',
    name: 'Restreamer/Location',
    minWidth: 150,
    maxWidth: 200,
    fieldName: 'restreamer_location',
    isResizable: true,
  },
  {
    key: 'date',
    name: 'Date',
    minWidth: 100,
    maxWidth: 100,
    fieldName: 'date_time',
    isResizable: false,
  },
  {
    key: 'player1',
    name: 'Player 1',
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
    onRender: (item) => {
      const classes = []
      if (item?.players[0]?.score > item?.players[1]?.score) {
        classes.push("winningPlayer")
      }
      return (
        <span className={classes.join(" ")}>{item?.players[0]?.name}</span>
      )
    }
  },
  {
    key: 'player1Score',
    name: 'Score',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    onRender: (item) => (
      <span>{item?.players[0]?.score}</span>
    )
  },
  {
    key: 'player2',
    name: 'Player 2',
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
    onRender: (item) => {
      const classes = []
      if (item?.players[1]?.score > item?.players[0]?.score) {
        classes.push("winningPlayer")
      }
      return (
        <span className={classes.join(" ")}>{item?.players[1]?.name}</span>
      )
    }
  },
  {
    key: 'player2Score',
    name: 'Score',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    onRender: (item) => (
      <span>{item?.players[1]?.score}</span>
    )
  },
]

const Dashboard = () => {
  const dispatch = useDispatch()
  const matches = useSelector(selectMatches)
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    dispatch(getMatches())
  }, [dispatch])

    // Debounced search function
    const debouncedSearch = useCallback(debounce((term) => {
      if (term.length > 2) {
        dispatch(searchMatches(term)); // Implement this action in your matches Redux slice
      }
      console.log("term?", term)
      if (!term) {
        dispatch(getMatches())
      }
    }, 300), [dispatch]); // 300 ms debounce time


  // Update search term and trigger debounced search
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    debouncedSearch(event.target.value);
  };

  return (
    <div className="matchList">
      <TextField
        placeholder="Search by player name..."
        value={searchTerm}
        onChange={handleSearchChange}
        styles={{ root: { padding: 10 } }}
      />
      <div className="matchTable" data-is-scrollable={true}>
        <DetailsList
          items={matches}
          columns={defaultColumns}
          getKey={(item) => item.id}
          setKey="none"
          selectionMode="none"
          checkboxVisibility={2}
        />
      </div>
    </div>
  )
}

export default Dashboard
