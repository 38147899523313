import client from "./client"
// import multiClient from "./multiClient"

export const authenticateUser = (username, password) => {
  return client.post('/login', {
    username,
    password,
  })
}

export const signUpUser = (email, password) => {
  return client.post('/users', {
    email,
    password,
    role: 'admin',
  })
}

export const setUserPassword = (id, password) => {
  return client.put(`/users/${id}`, {
    id,
    password,
  })
}

export const fetchUser = () => {
  return client.get('/auto_login')
}

export const fetchMatches = () => {
  return client.get("/matches")
}

export const searchMatches = (searchTerm) => {
  return client.get("/matches", {
    params: {
      search: searchTerm,
    }
  })
}

export const addMatch = (matchParams) => {
  return client.post("/matches", matchParams)
}

export const fetchElo = () => {
  return client.get("/players/elo")
}

export const fetchGlicko = () => {
  return client.get("/players/glicko")
}

export const fetchPlayersHistory = (historyDate) => {
  return client.get("/players/history", {
    params: {
      date: historyDate,
    }
  })
}

export const fetchPlayerOptions = (searchTerm) => {
  return client.get("/players/names", {
    params: {
      search: searchTerm,
    }
  })
}

export const fetchCompare = (player1Id, player2Id) => {
  return client.get(`/players/compare/${player1Id}/${player2Id}`)
}

export const exportElo = () => {
  return client.get(`/players/export`, {
    responseType: 'blob',
    headers: { 'Content-Type': 'text/csv' }
  })
}
