export const selectElo = (state) => {
  return state.players.elo || []
}

export const selectGlicko = (state) => {
  return state.players.glicko || []
}

export const selectPlayersHistory = (state) => {
  return state.players.history || []
}

export const selectCompare = (state) => {
  return state.players.compare || {}
}

export const selectSearchOptions1 = (state) => {
  const options = state.players.searchOptions1 || []
  const mappedOptions = options.map((option) => {
    return { key: option.id, text: option.name }
  })
  return mappedOptions
}

export const selectSearchOptions2 = (state) => {
  const options = state.players.searchOptions2 || []
  const mappedOptions = options.map((option) => {
    return { key: option.id, text: option.name }
  })
  return mappedOptions
}
