import { uniqueId } from 'lodash'

import {
  ADD_MATCH_FAILURE,
  ADD_MATCH_SUCCESS,
  CLEAR_NOTIFICATION,
  FETCH_MATCHES_FAILURE,
} from 'constants/actions'

import { TYPES } from 'constants/notifications'

const initialState = {
  active: [],
}

const createNewNotification = (message, type) => {
  return {
    id: uniqueId(),
    message,
    type,
  }
}

const reducer = (state = initialState, { type, data }) => {
  let message

  switch (type) {
    case FETCH_MATCHES_FAILURE:
    case ADD_MATCH_FAILURE:
      message = data?.message || "An error occurred with your request. Please try again."

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.error)]
      }
    case ADD_MATCH_SUCCESS:
      message = "Match added successfully."

      return {
        ...state,
        active: [...state.active, createNewNotification(message, TYPES.success)]
      }
    case CLEAR_NOTIFICATION:
      const current = state.active.slice()
      const removed = current.filter(notification => notification.id !== data.id)
      return {
        ...state,
        active: removed,
      }
    default:
      return state
  }
}

export default reducer
