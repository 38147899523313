import {
  FETCH_ELO_REQUESTED,
  FETCH_ELO_SUCCESS,
  FETCH_ELO_FAILURE,
  FETCH_GLICKO_REQUESTED,
  FETCH_GLICKO_SUCCESS,
  FETCH_GLICKO_FAILURE,
  FETCH_PLAYERS_HISTORY_REQUESTED,
  FETCH_PLAYERS_HISTORY_SUCCESS,
  FETCH_PLAYERS_HISTORY_FAILURE,
  FETCH_COMPARE_REQUESTED,
  FETCH_COMPARE_SUCCESS,
  FETCH_COMPARE_FAILURE,
  EXPORT_ELO_REQUESTED,
  EXPORT_ELO_SUCCESS,
  EXPORT_ELO_FAILURE,
  FETCH_PLAYER_OPTIONS_REQUESTED,
  FETCH_PLAYER_OPTIONS_SUCCESS,
  FETCH_PLAYER_OPTIONS_FAILURE,
 } from 'constants/actions'
 
 import {
   fetchElo as fetchEloRequest,
   fetchGlicko as fetchGlickoRequest,
   fetchPlayersHistory as fetchPlayersHistoryRequest,
   fetchCompare as fetchCompareRequest,
   exportElo as exportEloRequest,
   fetchPlayerOptions,
 } from 'api/requests'
 
 export const getElo = () => async (dispatch) => {
   dispatch({
     type: FETCH_ELO_REQUESTED,
   })
 
   try {
     const response = await fetchEloRequest()
 
     const responseData = response.data
 
     if (responseData) {
       dispatch({
         type: FETCH_ELO_SUCCESS,
         data: {
           players: responseData,
         },
       })
     }
   } catch (error) {
     dispatch({
       type: FETCH_ELO_FAILURE,
       error,
     })
   }
 }

 export const getGlicko = () => async (dispatch) => {
  dispatch({
    type: FETCH_GLICKO_REQUESTED,
  })

  try {
    const response = await fetchGlickoRequest()

    const responseData = response.data

    if (responseData) {
      dispatch({
        type: FETCH_GLICKO_SUCCESS,
        data: {
          players: responseData,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_GLICKO_FAILURE,
      error,
    })
  }
}

 export const getPlayersHistory = (historyDate) => async (dispatch) => {
  dispatch({
    type: FETCH_PLAYERS_HISTORY_REQUESTED,
  })

  try {
    const response = await fetchPlayersHistoryRequest(historyDate)

    const responseData = response.data

    if (responseData) {
      dispatch({
        type: FETCH_PLAYERS_HISTORY_SUCCESS,
        data: {
          players: responseData,
        },
      })
    }
  } catch (error) {
    dispatch({
      type:FETCH_PLAYERS_HISTORY_FAILURE,
      error,
    })
  }
}

 export const getComparison = (player1Id, player2Id) => async (dispatch) => {
  dispatch({
    type:FETCH_COMPARE_REQUESTED,
  })

  try {
    const response = await fetchCompareRequest(player1Id, player2Id)

    const responseData = response.data

    if (responseData) {
      dispatch({
        type: FETCH_COMPARE_SUCCESS,
        data: {
          compare: responseData,
        },
      })
    }
  } catch (error) {
    dispatch({
      type:FETCH_COMPARE_FAILURE,
      error,
    })
  }
}

export const exportElo = (companyId) => async (dispatch) => {
  dispatch({
    type: EXPORT_ELO_REQUESTED,
  })

  try {
    const response = await exportEloRequest(companyId)
    const responseData = response.data

    if (responseData) {
      const today = new Date()
      const downloadUrl = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `tetradb_elo_${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}.csv`)
      document.body.appendChild(link)
      link.click()
      link.remove()

      dispatch({
        type: EXPORT_ELO_SUCCESS,
      })
    }
  } catch (error) {
    dispatch({
      type: EXPORT_ELO_FAILURE
    })
  }
}

export const getPlayerOptions = (searchTerm, player1) => async (dispatch) => {
  dispatch({
    type: FETCH_PLAYER_OPTIONS_REQUESTED,
  })

  try {
    const response = await fetchPlayerOptions(searchTerm)
    const responseData = response.data

    if (responseData) {
      dispatch({
        type: FETCH_PLAYER_OPTIONS_SUCCESS,
        data: {
          searchOptions: responseData,
          player1,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_PLAYER_OPTIONS_FAILURE
    })
  }
}