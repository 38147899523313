import { exportElo, getElo } from "actions/players"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectElo } from "selectors/players"

import { DetailsList, PrimaryButton } from "@fluentui/react"

const defaultColumns = [
  {
    key: 'rank',
    name: 'Rank',
    minWidth: 50,
    maxWidth: 50,
    fieldName: 'rank',
    isResizable: false,
  },
  {
    key: 'name',
    name: 'Player',
    minWidth: 125,
    fieldName: 'name',
  },
  {
    key: 'score',
    name: 'Score',
    minWidth: 125,
    maxWidth: 125,
    fieldName: 'current_elo',
    isResizable: false,
  },
  {
    key: 'wins',
    name: 'Wins',
    minWidth: 50,
    maxWidth: 50,
    fieldName: 'wins',
    isResizable: false,
  },
  {
    key: 'losses',
    name: 'Losses',
    minWidth: 50,
    maxWidth: 50,
    fieldName: 'losses',
    isResizable: false,
  },
  {
    key: 'peak',
    name: 'Peak',
    minWidth: 100,
    maxWidth: 100,
    fieldName: 'highest_elo',
    isResizable: false,
  },
  {
    key: 'lastUpdate',
    name: 'Last Update',
    minWidth: 200,
    maxWidth: 200,
    fieldName: 'last_match_date',
    isResizable: false,
  },
]

const Elo = () => {
  const players = useSelector(selectElo)
  const dispatch = useDispatch()

  const handleExport = () => {
    dispatch(exportElo())
  }

  useEffect(() => {
    dispatch(getElo())
  }, [])

  return (
    <div className="matchList">
      <PrimaryButton onClick={handleExport}>
        Export
      </PrimaryButton>
      <div className="matchTable" data-is-scrollable={true}>
        <DetailsList
          items={players}
          columns={defaultColumns}
          getKey={(item) => item.id}
          setKey="none"
          selectionMode="none"
          checkboxVisibility={2}
        />
      </div>
    </div>
  )
}

export default Elo
