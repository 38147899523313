import {
  FETCH_ELO_FAILURE,
  FETCH_ELO_SUCCESS,
  FETCH_ELO_REQUESTED,
  FETCH_COMPARE_FAILURE,
  FETCH_COMPARE_REQUESTED,
  FETCH_COMPARE_SUCCESS,
  FETCH_PLAYERS_HISTORY_REQUESTED,
  FETCH_PLAYERS_HISTORY_FAILURE,
  FETCH_PLAYERS_HISTORY_SUCCESS,
  FETCH_GLICKO_REQUESTED,
  FETCH_GLICKO_SUCCESS,
  FETCH_GLICKO_FAILURE,
  FETCH_PLAYER_OPTIONS_REQUESTED,
  FETCH_PLAYER_OPTIONS_FAILURE,
  FETCH_PLAYER_OPTIONS_SUCCESS,
} from 'constants/actions'

const initialState = {
  isLoading: false,
  isError: false,
  elo: [],
  glicko: [],
  history: [],
  compare: {},
  searchOptions1: [],
  searchOptions2: [],
}

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case FETCH_ELO_REQUESTED:
    case FETCH_GLICKO_REQUESTED:
    case FETCH_COMPARE_REQUESTED:
    case FETCH_PLAYERS_HISTORY_REQUESTED:
    case FETCH_PLAYER_OPTIONS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_ELO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        elo: data.players,
      }
    case FETCH_GLICKO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        glicko: data.players,
      }
    case FETCH_PLAYERS_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        history: data.players,
      }
    case FETCH_COMPARE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        compare: data.compare,
      }
    case FETCH_PLAYER_OPTIONS_SUCCESS:
      if (data.player1) {
        return {
          ...state,
          isLoading: false,
          searchOptions1: data.searchOptions,
        }
      } else {
        return {
          ...state,
          isLoading: false,
          searchOptions2: data.searchOptions,
        }
      }
    case FETCH_ELO_FAILURE:
    case FETCH_GLICKO_FAILURE:
    case FETCH_COMPARE_FAILURE:
    case FETCH_PLAYERS_HISTORY_FAILURE:
    case FETCH_PLAYER_OPTIONS_FAILURE:
      return {
        ...state,
        isError: true,
      }
    default:
      return state
  }
}

export default reducer
