import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import classnames from 'classnames'

import { Spinner, SpinnerSize, Nav, DefaultButton } from "@fluentui/react"
import Notifications from "./Notifications"

// import logo from 'assets/logo.png'
import './AppLayout.scss'
import { selectUserIsAdmin } from "selectors/user"
import { signOut } from "actions/user"


const adminLinks = [
  {
    links: [
      {
        name: 'Match List',
        key: 'dashboard',
        url: '/dashboard',
      },
      {
        name: 'Elo',
        key: 'elo',
        url: '/elo',
      },
      {
        name: 'Glicko',
        key: 'glicko',
        url: '/glicko',
      },
      {
        name: 'History',
        key: 'history',
        url: '/history',
      },
      {
        name: 'Compare',
        key: 'compare',
        url: '/compare',
      },
      {
        name: "Add Match",
        key: "add",
        url: "/add",
      },
    ]
  }
]

const MainNav = () => {
  const history = useHistory()
  const handleNavClick = (ev, element) => {
    ev.preventDefault()
    history.push(element.url)
  }


  return (
    <div className="navBar">
      <Nav groups={adminLinks} onLinkClick={handleNavClick} />
      <div className="navBarFooter">
        <div>
          Questions?<br/>
          Msg Fireworks on Discord
        </div>
      </div>
    </div>
  )
}

const AppLayout = ({ children }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const loading = false
  const isAdmin = useSelector(selectUserIsAdmin)

  const handleSignOut = () => {
    dispatch(signOut())
    history.push('/login')
  }

  return (
    <>
      <header className="header">
        <Link to="/">
          <img src="/logo.png" alt="logo" />
        </Link>
        { isAdmin ? <DefaultButton className="signOutButton" onClick={handleSignOut}>
                      Sign Out
                    </DefaultButton> : null
        }
      </header>
      <MainNav />
      <div className={classnames("content", { navPresent: true })}>
        { loading ? <Spinner size={SpinnerSize.large} /> : children }
      </div>
      <Notifications />
    </>
  )
}

export default AppLayout
