import { getGlicko } from "actions/players"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectGlicko } from "selectors/players"

import { DetailsList } from "@fluentui/react"
import { orderBy } from "lodash"

const defaultColumns = [
  {
    key: 'rank',
    name: 'Rank',
    minWidth: 50,
    maxWidth: 50,
    fieldName: 'rank',
    isResizable: false,
    onRender: (item, index) => {
      return <span>{index+1}</span>
    }
  },
  {
    key: 'name',
    name: 'Player',
    minWidth: 125,
    fieldName: 'name',
  },
  {
    key: 'score',
    name: 'Score',
    minWidth: 125,
    maxWidth: 125,
    fieldName: 'current_glicko',
    isResizable: false,
  },
  {
    key: 'rd',
    name: 'RD',
    minWidth: 125,
    maxWidth: 125,
    fieldName: 'rating_deviation',
    isResizable: false,
  },
  {
    key: 'volatility',
    name: 'V',
    minWidth: 125,
    maxWidth: 125,
    fieldName: 'volatility',
    isResizable: false,
  },
  {
    key: 'wins',
    name: 'Wins',
    minWidth: 50,
    maxWidth: 50,
    fieldName: 'wins',
    isResizable: false,
  },
  {
    key: 'losses',
    name: 'Losses',
    minWidth: 50,
    maxWidth: 50,
    fieldName: 'losses',
    isResizable: false,
  },
  {
    key: 'peak',
    name: 'Peak',
    minWidth: 100,
    maxWidth: 100,
    fieldName: 'highest_glicko',
    isResizable: false,
  },
  {
    key: 'lastUpdate',
    name: 'Last Update',
    minWidth: 200,
    maxWidth: 200,
    fieldName: 'last_match_date',
    isResizable: false,
  },
]

const Glicko = () => {
  const players = useSelector(selectGlicko)
  const dispatch = useDispatch()

  const sortedPlayers = orderBy(players, 'current_glicko', ['desc'])

  useEffect(() => {
    dispatch(getGlicko())
  }, [])

  return (
    <div className="matchList">
      <p>Note: Glicko ratings are affected by inactivity and match volume heavily.  Players without match activity in the past 30 days or without at least 10 wins are excluded.</p>
      <div className="matchTable" data-is-scrollable={true}>
        <DetailsList
          items={sortedPlayers}
          columns={defaultColumns}
          getKey={(item) => item.id}
          setKey="none"
          selectionMode="none"
          checkboxVisibility={2}
        />
      </div>
    </div>
  )
}

export default Glicko
