import {
 FETCH_MATCHES_REQUESTED,
 FETCH_MATCHES_SUCCESS,
 FETCH_MATCHES_FAILURE,
 SEARCH_MATCHES_REQUESTED,
 SEARCH_MATCHES_SUCCESS,
 SEARCH_MATCHES_FAILURE,
 ADD_MATCH_REQUESTED,
 ADD_MATCH_SUCCESS,
 ADD_MATCH_FAILURE,
} from 'constants/actions'

import {
  fetchMatches as fetchMatchesRequest,
  searchMatches as searchMatchesRequest,
  addMatch as addMatchRequest,
} from 'api/requests'

export const getMatches = () => async (dispatch) => {
  dispatch({
    type: FETCH_MATCHES_REQUESTED,
  })

  try {
    const response = await fetchMatchesRequest()

    const responseData = response.data

    if (responseData) {
      dispatch({
        type: FETCH_MATCHES_SUCCESS,
        data: {
          matches: responseData,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_MATCHES_FAILURE,
      error,
    })
  }
}

export const searchMatches = (searchTerm) => async (dispatch) => {
  dispatch({
    type: SEARCH_MATCHES_REQUESTED,
  })

  try {
    const response = await searchMatchesRequest(searchTerm)

    const responseData = response.data

    if (responseData) {
      dispatch({
        type: SEARCH_MATCHES_SUCCESS,
        data: {
          matches: responseData,
        },
      })
    }
  } catch (error) {
    dispatch({
      type:SEARCH_MATCHES_FAILURE,
      error,
    })
  }
}

export const addMatch = (matchParams) => async (dispatch) => {
  dispatch({
    type: ADD_MATCH_REQUESTED,
  })

  try {
    const response = await addMatchRequest(matchParams)

    const responseData = response.data

    if (responseData) {
      dispatch({
        type: ADD_MATCH_SUCCESS,
        data: {
          matches: responseData,
        },
      })

      return response
    }
  } catch (error) {
    dispatch({
      type:ADD_MATCH_FAILURE,
      error,
    })
  }
}
