import { getPlayersHistory } from "actions/players"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectPlayersHistory } from "selectors/players"

import { DetailsList, Slider } from "@fluentui/react"
import { debounce } from "lodash"

const defaultColumns = [
  {
    key: 'rank',
    name: 'Rank',
    minWidth: 50,
    maxWidth: 50,
    fieldName: 'rank',
    isResizable: false,
  },
  {
    key: 'name',
    name: 'Player',
    minWidth: 125,
    fieldName: 'name',
  },
  {
    key: 'score',
    name: 'Score',
    minWidth: 125,
    maxWidth: 125,
    fieldName: 'current_elo',
    isResizable: false,
  },
  {
    key: 'wins',
    name: 'Wins',
    minWidth: 50,
    maxWidth: 50,
    fieldName: 'wins',
    isResizable: false,
  },
  {
    key: 'losses',
    name: 'Losses',
    minWidth: 50,
    maxWidth: 50,
    fieldName: 'losses',
    isResizable: false,
  },
  {
    key: 'peak',
    name: 'Peak',
    minWidth: 100,
    maxWidth: 100,
    fieldName: 'highest_elo',
    isResizable: false,
  },
  {
    key: 'lastUpdate',
    name: 'Last Update',
    minWidth: 200,
    maxWidth: 200,
    fieldName: 'last_match_date',
    isResizable: false,
  },
]

const startDate = new Date(2010, 7)
const endDate = new Date()
const totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth() + 1;

const History = () => {
  const [monthOffset, setMonthOffset] = useState(totalMonths - 1);
  const players = useSelector(selectPlayersHistory)
  const dispatch = useDispatch()

  const fetchHistory = useCallback(debounce((targetDate) => {
    dispatch(getPlayersHistory(targetDate.getTime()))
  }, 300), [])

  useEffect(() => {
    const targetDate = new Date(startDate)
    targetDate.setMonth(startDate.getMonth() + monthOffset)
    fetchHistory(targetDate)
  }, [fetchHistory, monthOffset, dispatch])

  const formatMonth = (value) => {
    const date = new Date(startDate);
    date.setMonth(startDate.getMonth() + value);
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  };

  return (
    <div className="matchList">
        <Slider
          label="Select Month and Year"
          min={0}
          max={totalMonths - 1}
          value={monthOffset}
          onChange={setMonthOffset}
          
          showValue
          valueFormat={formatMonth}
          styles={{ root: { margin: 20 } }}
        />
      <div className="matchTable" data-is-scrollable={true}>
        <DetailsList
          items={players}
          columns={defaultColumns}
          getKey={(item) => item.id}
          setKey="none"
          selectionMode="none"
          checkboxVisibility={2}
        />
      </div>
    </div>
  )
}

export default History
